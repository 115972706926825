<template>
  <div>
    <v-card
      max-width="500"
      :min-height="$vuetify.breakpoint.mdAndUp ? '625' : '550'"
      class="my-md-8  mx-auto "
      :class="$vuetify.breakpoint.smAndUp ? 'card-radius' :'' "
      color="#C3FFF5"
      flat
    >
      <v-row class="justify-end mx-6">
        <lang-swicther class="mt-3" />
      </v-row>
      <v-card-text class="pt-16">
        <v-img
          :src="require('../../../assets/logo.png')"
          width="90px"
          contain
          class="mx-auto"
        />
      </v-card-text>
      <v-card-text>
        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)">
            <v-layout>
              <v-flex>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="phone"
                  rules="required|max:8|min:8"
                >
                  <v-text-field
                    class="custom-field mx-auto"
                    v-model="mobileNumber"
                    :error-messages="errors"
                    :placeholder="$t('login.mobileNumber')"
                    required
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    type = "number"
                    maxlength = "8"
                    outlined
                    rounded
                    dense
                  ></v-text-field>
                  
                </ValidationProvider>
              </v-flex>
            </v-layout>
            <v-layout class="mt-2">
              <v-flex>
                <ValidationProvider
                  v-slot="{ errors }"
                  name="password"
                  rules="required|max:20|min:6"
                >
                  <v-text-field
                    class="custom-field mx-auto "
                    v-model="password"
                    :placeholder="$t('login.password')"
                    required
                    maxlength="20"
                    outlined
                    :error-messages="errors"
                    type="password"
                    rounded
                    dense
                  ></v-text-field>
                  
                </ValidationProvider>
              </v-flex>
            </v-layout>
            <v-layout justify-center class="mt-3">
              <v-btn
                depressed
                rounded
                color="primary custom-font"
                class="black--text font-weight-light mt-2"
                :width="$vuetify.breakpoint.xs ? 220 : 260"
                type="submit"
                :loading="btn_loading"
                :disabled="!isValidForm"
                >{{ $t("login.btnTitle") }}</v-btn
              >
            </v-layout>
           <p class="text-center text-caption mt-2"
                @click="$router.push('/forget_password')">{{ $t('login.forgetPassword') }}</p>
            <v-row class="justify-end mt-2 px-12">
              
            </v-row>
            <v-row class="justify-center mt-2 px-md-10">
              <p class="custom-text-new">
                {{ $t("login.newUser") }}
                <b class="pointer" @click="$router.push('/user_phone')">
                  {{ $t("login.subscribe") }}</b
                >
              </p>
            </v-row>
            <v-layout justify-center class="">
              <v-btn
                depressed
                rounded
                color="primary custom-font"
                class="black--text font-weight-light mb-10"
                :width="$vuetify.breakpoint.xs ? 220 : 260"
                @click="menu = !menu"
                type="button"
                >{{ $t("login.menuBtn") }}</v-btn
              >
            </v-layout>
          </form>
        </ValidationObserver>
        <p v-if="errorFromApi" class="red--text text-center text-caption mt-6">
          {{ errorFromApi }}
        </p>
      </v-card-text>
    </v-card>
    <v-dialog v-model="menu" max-width="985" :fullscreen="$vuetify.breakpoint.smAndDown">
      <v-card max-width="985" class="card-radius secondary">
        <v-card-title>
          <v-row align="center" class="justify-space-between px-0 px-md-4 mt-8">
            <span class="text-subtitle-2 text-md-h6 mt-2">
              {{ $t("package.choosePackage") }}
            </span>

            <v-icon large class="black--text" @click="menu = !menu">
              mdi-close-circle
            </v-icon>
          </v-row>
        </v-card-title>
        <v-card-text class="px-0 px-md-4">
          <MenuCard />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { signIn } from "../../methods/signIn";
import "@/core/methods/validation_rules";

export default {
  data() {
    return {
      btn_loading: false,
      errorFromApi: null,
      mobileNumber: "",
      password: "",
      menu: false,
    };
  },
  computed:{
     isValidForm(){
       return this.mobileNumber.length === 8 && this.password.length >=6 && this.password.length <=20
     }
  },
  components: {
    "lang-swicther": () => import("@/core/components/LangSwitch"),
    MenuCard: () => import("./MenuCard.vue"),
  },
  methods: {
    onSubmit() {
      this.$refs.observer.validate();
      this.btn_loading = true;
      const input = {
        phone: this.mobileNumber,
        password: this.password,
      };
      signIn({ input: input }).then((data) => {
        this.btn_loading = false;
        if (data.graphQLErrors) {
          this.errorFromApi = data.graphQLErrors[0].message;
          setTimeout(() => {
            this.errorFromApi = null;
          }, 5000);
        }
        if (data.data) {
          localStorage.setItem("apollo-done-token", data.data.signIn.token);
          this.$router.push("/home");
        }
      });
    },
  },
};
</script>

<style scoped>
.custom-text-new {
  font-family: "Gotham";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 11px;
  letter-spacing: 0em;
  text-align: left;
}

.custom-field >>> .v-input__slot{
  background-color: white !important;
}
</style>
